"use client";

import { useEffect, useState } from "react";
import Image from "next/image";

export function FloatingImages({ original_images = [] }) {
  const [images, setImages] = useState([]);

  useEffect(() => {
    // Filter out images with invalid URLs
    const validImages = original_images.filter((image) => {
      return image && image.image && typeof image.image === "string" && image.image.trim().length > 0;
    });

    const uniqueImages = Array.from(new Map(validImages.map((image) => [image.id, image])).values());

    const newImages = uniqueImages.slice(0, 30).map((attachment, index) => ({
      ...attachment,
      uniqueKey: `${attachment.id}-${index}`, // Create a unique key
      x: Math.random() * 100,
      y: Math.random() * 100,
      speed: 0.5 + Math.random() * 1.5,
      size: 40 + Math.random() * 40,
      opacity: 0.4 + Math.random() * 0.5,
    }));
    setImages(newImages);

    const interval = setInterval(() => {
      setImages((prevImages) =>
        prevImages.map((img) => ({
          ...img,
          x: (img.x + img.speed / 10) % 100,
          y: (img.y + Math.sin(img.x / 10) / 2) % 100,
        }))
      );
    }, 50);

    return () => clearInterval(interval);
  }, []);

  return (
    <div className="fixed inset-0 pointer-events-none overflow-hidden">
      {images.map((img) => (
        <div
          key={img.uniqueKey}
          className="absolute transition-all duration-1000 ease-in-out"
          style={{
            left: `${img.x}%`,
            top: `${img.y}%`,
            transform: `translate(-50%, -50%)`,
          }}
        >
          <Image unoptimized src={img.image} alt={img.name} width={img.size} height={img.size} className="rounded-md opacity-20" />
        </div>
      ))}
    </div>
  );
}
