"use client";

import { Input } from "@/components/ui/input";
import { Search, Loader2 } from "lucide-react";
import { useState } from "react";

export function SearchInput({ onSearch, isSearching = false }) {
  const [value, setValue] = useState("");

  return (
    <div className="relative">
      <Input
        name="query"
        value={value}
        placeholder="Search for movies, books, music..."
        onChange={(e) => {
          setValue(e.target.value);
          const formData = new FormData();
          formData.append("query", e.target.value);
          onSearch(formData);
        }}
        className="border-2 border-white bg-transparent font-mono pr-10 text-white"
        autofill="false"
        autoComplete="off"
      />
      <div className="absolute right-0 top-0 h-full flex items-center pr-3">{isSearching ? <Loader2 className="h-4 w-4 animate-spin" /> : <Search className="h-4 w-4" />}</div>
    </div>
  );
}
