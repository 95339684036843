"use client";

import { useState, useEffect } from "react";
import { storeSearchQuery } from "@/app/actions/search";
import { Loader2 } from "lucide-react";
import { SearchInput } from "./HomeSearchInput";
import { useDebounce } from "@/hooks/useDebounce";
import Image from "next/image";
import { toast } from "sonner";

function isValidUrl(url) {
  try {
    new URL(url);
    return true;
  } catch {
    return false;
  }
}

export function SearchComponent() {
  const [results, setResults] = useState([]);
  const [isSearching, setIsSearching] = useState(false);
  const [hasSearched, setHasSearched] = useState(false);
  const [query, setQuery] = useState("");
  const [error, setError] = useState("");
  const [selectedResult, setSelectedResult] = useState(null);
  const debouncedQuery = useDebounce(query);

  async function handleSearch(formData) {
    const searchQuery = formData.get("query");
    setQuery(searchQuery);
    setError(""); // Clear any previous errors
  }

  async function handleResultClick(result) {
    try {
      const response = await storeSearchQuery(result.id, query);
      if (response.success) {
        setSelectedResult(result.id);
        toast.success("Perfect! Search for more things", {
          className: "font-mono",
        });
      } else {
        toast.error("Failed to add to homepage");
      }
    } catch (error) {
      console.error("Error storing search query:", error);
      toast.error("Failed to add to homepage");
    }
  }

  async function handleMissingQuery() {
    try {
      const response = await storeSearchQuery(null, query, true);
      if (response.success) {
        toast.success("Thanks for letting us know! We'll add it to the database.", {
          className: "font-mono",
        });
      } else {
        toast.error("Failed to submit missing query");
      }
    } catch (error) {
      console.error("Error submitting missing query:", error);
      toast.error("Failed to submit missing query");
    }
  }

  // Effect to handle the actual search when query changes
  useEffect(() => {
    async function performSearch() {
      if (!debouncedQuery) {
        setResults([]);
        setHasSearched(false);
        setError("");
        return;
      }

      setIsSearching(true);
      try {
        const response = await fetch(`/api/homepage/search?q=${encodeURIComponent(debouncedQuery)}`);
        const data = await response.json();

        if (!response.ok) {
          throw new Error(data.error || "Search failed");
        }

        setResults(data.results);
        setHasSearched(true);
        setError(data.error || "");
      } catch (error) {
        console.error("Search failed:", error);
        setError("An error occurred while searching");
      } finally {
        setIsSearching(false);
      }
    }

    performSearch();
  }, [debouncedQuery]);

  return (
    <div className="space-y-6">
      <SearchInput onSearch={handleSearch} isSearching={isSearching} />

      {error && <div className="text-red-500 text-center py-2">{error}</div>}

      {isSearching && (
        <div className="flex justify-center py-8">
          <Loader2 className="h-8 w-8 animate-spin text-white" />
        </div>
      )}

      {hasSearched && !isSearching && !error && (
        <div className="mt-6">
          {results.length > 0 ? (
            <div className="space-y-4">
              <p className="text-sm text-zinc-400">
                Found {results.length} result{results.length !== 1 ? "s" : ""} for "{query}"
              </p>
              <div className="space-y-3">
                {results.map((result) => (
                  <div
                    key={result.id}
                    className={`border-2 border-white p-4 font-mono bg-black hover:bg-white/5 transition-colors cursor-pointer relative group ${
                      selectedResult === result.id ? "border-green-500" : ""
                    }`}
                    onClick={() => handleResultClick(result)}
                  >
                    <div className="flex flex-col md:flex-row md:items-center justify-between gap-2">
                      <div className="flex items-center gap-4">
                        {result.image && isValidUrl(result.image) && <Image src={result.image} alt={result.name} width={64} height={64} className="w-16 h-16 object-cover rounded" />}
                        <div>
                          <h3 className="text-lg font-bold">{result.name}</h3>
                          <div className="space-y-1">
                            {result.details.map((detail, index) => (
                              <p key={index} className="text-sm text-zinc-400">
                                {detail.data_value}
                              </p>
                            ))}
                          </div>
                        </div>
                      </div>
                      <div className="flex flex-wrap gap-2 mt-2 md:mt-0">
                        {result.thingTypes?.map((type) => (
                          <span key={type.id} className="inline-block px-2 py-1 text-xs border border-white text-white">
                            {type.name}
                          </span>
                        ))}
                      </div>
                    </div>
                    <div className="absolute inset-0 flex items-center justify-center opacity-0 group-hover:opacity-100 transition-opacity bg-green-600/90">
                      <div className="flex flex-col items-center gap-2 ">
                        <svg className="w-8 h-8 text-white" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2.5} d="M5 13l4 4L19 7" />
                        </svg>
                        <span className="text-white text-lg font-medium">This is what I was looking for</span>
                      </div>
                    </div>
                  </div>
                ))}
                <div className="border-2 border-white p-4 font-mono bg-black hover:bg-white/5 transition-colors cursor-pointer relative group" onClick={handleMissingQuery}>
                  <div className="flex items-center justify-center gap-4">
                    <div>
                      <h3 className="text-lg font-bold text-orange-400">None of these match what you're looking for?</h3>
                      <p className="text-sm text-zinc-400">Click to let me know.</p>
                    </div>
                  </div>
                  <div className="absolute inset-0 flex items-center justify-center opacity-0 group-hover:opacity-100 transition-opacity bg-orange-600/90">
                    <div className="flex flex-col items-center gap-2">
                      <svg className="w-8 h-8 text-white" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2.5} d="M12 6v6m0 0v6m0-6h6m-6 0H6" />
                      </svg>
                      <span className="text-white text-lg font-medium">Let me know</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div className="border-2 border-white p-6 text-center">
              <p className="text-lg font-mono">No results found for "{query}"</p>
              <p className="text-sm mt-2 text-zinc-400">
                Can't find what you're looking for?{" "}
                <button onClick={handleMissingQuery} className="underline hover:text-white transition-colors">
                  Click here to let us know
                </button>
              </p>
            </div>
          )}
        </div>
      )}
    </div>
  );
}
