"use client";

import { useState, useEffect } from "react";
import { addToQueue } from "@/app/actions/addToQueue";

export default function QueueForm() {
  const [email, setEmail] = useState("");
  const [username, setUsername] = useState("");
  const [status, setStatus] = useState("");
  const [referral, setReferral] = useState(null);
  const [submittedUsername, setSubmittedUsername] = useState(null);
  const [referrals, setReferrals] = useState(0);
  const [showStatusForm, setShowStatusForm] = useState(false);
  const [input, setInput] = useState("");
  const [result, setResult] = useState(null);
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [referralLink, setReferralLink] = useState("");

  useEffect(() => {
    if (submittedUsername && typeof window !== "undefined") {
      setReferralLink(`${window.location.origin}?referral=${submittedUsername}`);
    }
  }, [submittedUsername]);

  useEffect(() => {
    if (typeof window !== "undefined") {
      const params = new URLSearchParams(window.location.search);
      const referralCode = params.get("referral");
      if (referralCode) {
        setReferral(referralCode);
      }

      const storedUsername = localStorage.getItem("submittedUsername");
      if (storedUsername) {
        setSubmittedUsername(storedUsername);
        fetchReferralCount(storedUsername);
        setInput(storedUsername);
        handleCheckStatus(null, storedUsername);
        setError("");
        setShowStatusForm(true);
      }
    }
  }, []);

  const fetchReferralCount = async (username) => {
    try {
      const response = await fetch(`/api/queue/referrals?username=${username}`);
      if (!response.ok) {
        throw new Error("Failed to fetch referrals");
      }
      const data = await response.json();
      setReferrals(data.referrals || 0);
    } catch (err) {
      console.error(err.message);
    }
  };

  const handleSubmit = async (e) => {
    if (e) {
      e?.preventDefault(); // Prevent page reload
    }
    setStatus("");

    try {
      const formData = new FormData(e.target);
      if (referral) {
        formData.append("referral", referral);
      }
      const response = await addToQueue(formData);
      if (!response.success) {
        setError(response.error);
      } else {
        setError(null);
        setSubmittedUsername(username);
        localStorage.setItem("submittedUsername", username);
        setStatus("Successfully added to the queue!");

        handleCheckStatus(null, username);
      }
    } catch (err) {
      setStatus(`Error: ${err.message}`);
    }
  };

  const handleCheckStatus = async (e, username) => {
    if (e) {
      e?.preventDefault(); // Prevent page reload
    }
    if (result) return;
    if (!input) {
      setError("Please enter a username or email.");
      return;
    }

    const targetUsername = username || input;

    setError("");
    setLoading(true);

    try {
      const response = await fetch(`/api/queue/status?input=${targetUsername}`);
      const data = await response.json();

      if (response.ok) {
        setResult(data);
        setShowStatusForm(true);

        if (data.target_username) {
          setSubmittedUsername(data.target_username);
          localStorage.setItem("submittedUsername", data.target_username);
        }
      } else {
        setError(data.error || "Something went wrong.");
      }
    } catch (err) {
      setError("Failed to connect to the server.");
    } finally {
      setLoading(false);
    }
  };

  if (showStatusForm) {
    return (
      <div className="w-full mx-auto bg-transparent text-white border-2 border-white p-6 md:p-8 text-lg -rotate-1">
        {result ? (
          <div className="space-y-6">
            <div className="text-3xl md:text-4xl font-semibold">@{result.target_username}</div>

            <div className="flex flex-row gap-10 items-center justify-evenly">
              <div className="flex flex-col gap-2">
                <div className="text-3xl md:text-4xl font-bold">{result.people_ahead}</div>
                <div className="text-sm whitespace-nowrap">People in front of you</div>
              </div>
              <div className="flex flex-col gap-2">
                <div className="text-3xl md:text-4xl font-bold">{result.people_behind}</div>
                <div className="text-sm whitespace-nowrap">People behind you</div>
              </div>
            </div>

            {result.referred_by_username && (
              <p>
                <strong>Referred by @{result.referred_by_username}</strong>
              </p>
            )}

            <div className="border-2 border-white bg-black p-4 gap-2 flex flex-col rotate-1">
              <div className="text-sm font-bold">Referrals: {result.referral_count}</div>
              <div className="flex flex-col gap-0">
                <code className="bg-black/50 p-2 text-xs md:text-sm break-all">{referralLink}</code>
                <button
                  onClick={() => navigator.clipboard.writeText(referralLink)}
                  className="text-sm mt-4 px-4 py-2 bg-blue-600 text-white border-2 border-white hover:bg-blue-700 transform hover:translate-y-[-2px] transition-transform"
                >
                  Copy Referral Link
                </button>
              </div>
            </div>

            <div className="flex flex-col justify-center md:flex-row gap-2 items-center">
              <button onClick={() => setResult(null)} className="mt-2 text-sm underline decoration-dotted">
                Back
              </button>
            </div>
          </div>
        ) : (
          <div className="flex flex-col gap-8">
            <form onSubmit={handleCheckStatus} className="space-y-4">
              <input
                type="text"
                placeholder="enter your username or email"
                value={input}
                onChange={(e) => setInput(e.target.value)}
                required
                className="w-full text-lg pb-2 font-semibold text-white text-center placeholder-gray-500 border-b-2 border-white bg-transparent outline-none focus:border-blue-400 transition-all"
              />
              {error && <p className="text-sm text-red-500">{error}</p>}
              <button
                type="submit"
                className="w-full px-4 py-2 text-white font-semibold text-sm bg-blue-600 border-2 border-white hover:bg-blue-700 transform hover:translate-y-[-2px] transition-transform"
              >
                {loading ? "Checking..." : "Check Status"}
              </button>
            </form>
            <button onClick={() => setShowStatusForm(false)} className="text-sm underline decoration-dotted">
              Back
            </button>
          </div>
        )}
      </div>
    );
  }

  return (
    <form onSubmit={handleSubmit} className="flex flex-col space-y-8">
      <div className="border-l-4 border-white pl-4">
        <input
          type="email"
          name="email"
          id="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          required
          placeholder="your email"
          className="w-full text-lg pb-2 font-semibold text-white placeholder-gray-500 border-b-2 border-white bg-transparent outline-none focus:border-blue-400 transition-all"
        />
      </div>
      {process.env.NEXT_PUBLIC_SHOW_USERNAME_FIELD === "true" && (
        <div className="border-l-4 border-white pl-4">
          <input
            type="text"
            name="username"
            id="username"
            placeholder="pick a username"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            required
            className="w-full text-lg pb-2 font-semibold text-white placeholder-gray-500 border-b-2 border-white bg-transparent outline-none focus:border-blue-400 transition-all"
          />
        </div>
      )}

      {status && <p className="text-sm mt-2 text-green-400">{status}</p>}
      {error && <p className="text-sm mt-2 text-red-500">{error}</p>}

      <div className="flex flex-col gap-4">
        <button type="submit" className="px-8 py-3 text-white font-semibold text-xl bg-black border-2 border-white hover:bg-blue-700 transform hover:translate-y-[-2px] transition-transform rotate-1">
          Join the waitlist
        </button>

        <button
          type="button"
          onClick={() => setShowStatusForm(true)}
          className="px-4 py-2 text-white font-semibold text-sm border-0 border-white hover:bg-white/10 transform hover:translate-y-[-2px] transition-transform -rotate-1"
        >
          Check Status
        </button>
      </div>
    </form>
  );
}
